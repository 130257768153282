import React, { useState,useRef } from "react";
import "../../Assets/Styles/Python.scss"
import ModalPage from "./ModalPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Python = ({ }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_iqgcbdn',
            'template_voa3yk9',
            form.current,
            'IzhxCt_ZfLQi2M9iK'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div className="container">
            <h2>
                <div>
                    <h4 className='pythontitle'>About Advance Java Training</h4>
                    <p className='paraPython'>Java is an object-oriented language that allows learners to make
                        real-world applications. Java technology based mostly software package works almost everywhere
                        – from the tiniest devices to supercomputers! Java technology parts aren’t compact by the
                        type of computer, phone, good device, or operating systems they’re running on.
                        The architecture-neutral nature of Java technology is very important in a networked world wherever
                        one cannot predict the type of devices that partners, suppliers, and workers use to attach to their
                        organizations.
                    </p>
                </div>
                <div>
                    <h4 className='pythontitle'>Advance Java Courses at DLK</h4>
                    <div className="col col-md-12 d-flex flex-md-row flex-column">
                        <div className="col col-md-12">
                            <text>
                                <p className='paraPython'>
                                    Java is an object-oriented language that enables learners to make true applications.
                                    Java innovation based for the most part programming bundle works all over the place –
                                    from the littlest gadgets to super PCs! Java innovation parts aren’t reduced by the
                                    sort of PC, telephone, great gadget, or operation frameworks they’re running on.
                                    The engineering impartial nature of Java innovation is vital in an arranged world
                                    wherever one can’t foresee the kind of gadgets that accomplices, providers, and
                                    specialists use to connect to their associations.
                                </p>
                                <p className='paraPython1'>
                                    <strong>Advanced Java</strong> is also a part of Java Programming Language.
                                    It fabricates web-based, network-centric or enterprise applications through Java Programming Language.
                                    This is a specialisation of a specific domain. Core java covers web services, JSP,
                                    database connectivity, Servlets, EJB, etc. Core java assists a user in building mobile and android applications.
                                    This thrives to build java training for the purpose of business and profits.
                                </p>
                                <p className='paraPython1'>
                                    We provide the <strong>best advanced java training for engineering students,</strong>
                                    computer science students as well as beginners in Chennai at an affordable price.
                                    We ensure that the students can optimise and choose the categories of
                                    training duration at their comfort. This helps students learn and gain
                                    knowledge economically with efficiency. DLK Career Development is a well
                                    known training institute and <strong>coaching centre for students in Chennai.</strong>
                                    Java language is taught with perfect proficiency by our professional
                                    experts and executives in a flawless and interesting way, offering 100% placement
                                    and certification of the course in Chennai.
                                </p>
                            </text>
                        </div>
                    </div>
                    <h4 className='pythontitle'>Java Training In Chennai Offers</h4>
                    <div className="col col-md-12 d-flex flex-md-row flex-column">
                        <div className="col col-md-12">
                            <text>
                                <p className='paraPython'>
                                    Offers a wide variety of JAVA training courses in Chennai to meet the
                                    rising needs of companies. Trainers who have many years of experience in
                                    leading IT companies prepare the course materials and the syllabus.
                                    In Chennai, we offer Java Training with Placement in leading companies.
                                    Step into our office to find our students’ list of firms.
                                </p>

                            </text>
                        </div>
                    </div>
                    <h4 className='pythontitle'>Java Course Syllabus</h4>
                    <div className="col col-md-12 d-flex flex-md-row flex-column">
                        <div className="col col-md-12">
                            <text>
                                <p className='paraPython'>
                                    Our Java Course Syllabus is designed to master the Java programming,
                                    development, and other important tools required to build J2EE, J2SE,
                                    and J2ME applications. Advance Java Training Institute in Chennai Our course
                                    kit provides comprehensive practical focused training pointing to real-world
                                    problems that help students gain exposure to the industry and trust to work
                                    with any job needs. Java Training at Chennai in Java will keep the syllabus
                                    up to date with the latest industry changes and provide a quality education
                                    through expert trainers.
                                </p>
                                <p className='paraPython1'>
                                    Experienced IT professionals with 12 + years of real time experience in the
                                    software development industry offer Java courses in Chennai at JAVA training
                                    in Chennai branches. Our trainers have good experience in the development of
                                    Java applications, ensuring the best quality training. We give Java experts
                                    professional training in Java technologies with the goal of bridging the gap
                                    between college education and the IT industry. We give 100 percent placement
                                    and Java certification help to all of our students after completing Java
                                    Training in Chennai at our university. The specific approach makes us Chennai’s
                                    best Java centers.
                                </p>
                            </text>
                        </div>
                    </div>
                    <div className='col col-md-12 col-12 watchMore'>
                        <button type="button" class="started"
                            onClick={showModal}
                        >Contact Us</button>
                    </div>
                    <ModalPage
                        onFinishFailed={onFinishFailed}
                        onFinish={onFinish}
                        handleCancel={handleCancel}
                        showModal={showModal}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        sendEmail={sendEmail}
                        form={form}
                    />
                </div>
            </h2>
        </div>
    );
};

export default Python;