import React, { useState, useRef } from 'react'
import "../../Assets/Styles/Content.css"
import InsideNav from './InsideNav';
import Section1Content from './Section1Content';
import Offer from './Offer'
import PgrmHighlight from './PgrmHighlight';
import CourseContent from './CourseContent';
import BannerContent from './BannerContent';
import Guidance from './Guidance';
import TrustedPeople from './TrustedPeople';
import HappyStudent from './HappyStudent';
import Trainer from './Trainer';
import Python from './Python';
import Branches from './Branches';
import Copyright from './Copyright';
import Gallery from './Gallery';
import ModalPage from './ModalPage';
import { HiMail } from "react-icons/hi"
import FooterAnime from './FooterAnime';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Content = () => {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending Mail....");
    emailjs.sendForm(
      'service_iqgcbdn',
      'template_voa3yk9',
      form.current,
      'IzhxCt_ZfLQi2M9iK'
    )
      .then((result) => {
        console.log(result.text);
        toast.success("Message Sent Successfully! We will get back to you soon.");
        e.target.reset();
        setIsModalOpen(false);
        console.log("message sent");
      }, (error) => {
        toast.error("Message Sending Failed! Please try again.")
        console.log(error.text);
      });
  };
  return (
    <div>
      <div class="sticky-left-container d-md-flex d-none">
        <ul class="sticky-left">
          <li>
            <img width="32" height="32" title="" alt="" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png" />
            <p onClick={() => window.open("https://www.facebook.com/DlkTechnologies/")}>Facebook</p>
          </li>
          <li>
            <img width="32" height="32" title="" alt="" src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png" />
            <p onClick={() => window.open("https://twitter.com/dlktechnologie/")}>Twitter</p>
          </li>
          <li>
            <img width="32" height="32" title="" alt="" src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-youtube-social-media-round-icon-png-image_6315993.png" />
            <p onClick={() => window.open("https://www.youtube.com/@dlkcareerdevelopment3837")}>Youtube</p>
          </li>
          <li>
            <img width="32" height="32" title="" alt="" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/640px-LinkedIn_logo_initials.png" />
            <p onClick={() => window.open("https://www.linkedin.com/company/dlktechnologies/")}>LinkedIn</p>
          </li>
          <li>
            <img width="32" height="32" title="" alt="" src="https://i.pinimg.com/originals/72/9f/77/729f7798561be2cb67f39e916a22eb6a.png" />
            <p onClick={() => window.open("https://www.instagram.com/dlk_tech/")}>Instagram</p>
          </li>
        </ul>
      </div>
      <div class="sticky-container">
        <ul class="sticky">
          <li>
            <img width="32" height="32" title="" alt="" src="https://thumbs.dreamstime.com/b/phone-icon-red-square-button-isolated-abstract-illustration-103958751.jpg" />
            <p onClick={showModal}>Enquiry Now</p>
            <ModalPage
              onFinishFailed={onFinishFailed}
              onFinish={onFinish}
              handleCancel={handleCancel}
              showModal={showModal}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              sendEmail={sendEmail}
              form={form}
            />
          </li>
        </ul>
      </div>
      <section >
        <div className='col col-md-12'>
          <div className='main'>
            <div class="swiper-container">
              <div class="bg"></div>
              <div class="bg bg2"></div>
              <div class="bg bg3"></div>
            </div>
            <InsideNav />
            <Section1Content />
          </div>
        </div>
      </section>
      <section className='sections'>
        <div className='col col-md-12 trainingwholeMain'>
          <Offer />
        </div>
      </section>
      <section className=''>
        <div className='col col-md-12 '>
          <Gallery />
        </div>
      </section>
      <section id='pgrm_highlight'>
        <div className='col col-md-12'>
          <PgrmHighlight />
        </div>
      </section>
      <section id='course'>
        <div className='col col-md-12 '>
          <CourseContent />
        </div>
      </section>
      <section className='guidance'>
        <Guidance />
      </section>
      <section>
        <BannerContent />
      </section>
      <section id='testimonial'>
        <TrustedPeople />
      </section>
      <section>
        <HappyStudent />
      </section>
      <section id="profile">
        <Trainer />
      </section>
      <section>
        <Python />
      </section>
      <section>
        <Branches />
      </section>
      <section>
        <FooterAnime />
      </section>
      <section>
        <Copyright />
      </section>
      <section >
        <div >
          <main>
          </main>
          <footer className='myfooter'>
            <h2 className='contact mt-2'>
              call@ +91 9751800789 | +91 7299951536 |
              &nbsp;
              <span><br class="d-md-none"/><HiMail /> studentinternship15@gmail.com
              </span>
            </h2>
          </footer>
        </div>
      </section>
    </div>

  )
}

export default Content