import React, { useState,useRef } from 'react'
import "../../Assets/Styles/CourseContent.scss"
import { Scrollbar } from 'react-scrollbars-custom';
import ModalPage from './ModalPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
const data = [
    {
        id: 1,
        label: "Module 1: Introduction (History of Java)"
    },
    {
        id: 2,
        label: "Module 2: Introduction (JSP)"
    },
    {
        id: 3,
        label: "Module 3: Scripting elements"
    },
    {
        id: 4,
        label: "Module 4: 9 Implicit Objects"
    },
    {
        id: 5,
        label: "Module 5: Directive Elements"
    },
    {
        id: 6,
        label: "Module 6: Action Elements"
    },
    {
        id: 7,
        label: "Module 7: Basics of Servlet and ServletRequest"
    },
    {
        id: 8,
        label: "Module 8: Session Tracking"
    }
];
const CourseContent = () => {
    let [isActive1, setIsActive1] = useState(true);
    const [selectedId, setSelectedId] = useState(1);

    let squares = [];

    for (let i = 0; i < 20; i++) {
        squares.push(i);
    }

    const generateRandomNum = ({ min, max }) => Math.floor(Math.random() * (max - min + 1) + min);

    const handleClick = (id) => {
        setSelectedId(id !== selectedId ? id : id);
        setIsActive1()
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const form = useRef();
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_j08cs4l',
            'template_voa3yk9',
            form.current,
            'IzhxCt_ZfLQi2M9iK'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div className='courseMain'>
            <div>
                <div className='col col-md-12 d-flex justify-content-center trainingheading'>
                    <text className='programhighlightsTitile'>Advance Java Training Course Content</text>
                </div>
                <div className='d-flex justify-content-center mt-3' >
                    <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
                </div>
            </div>
            <div className='col col-md-12 d-flex flex-row'>
                <div className='col col-md-1'></div>
                <div className="intro col col-md-10 col-12 p-2 d-flex justify-content-center ">
                    <div className="quote">
                        <h1>Course Syllabus</h1>
                    </div>
                    <div className="squares-wrapper">
                        <ul className="squares">
                            {squares.map((el, i) => {
                                const randomDimensions = Math.floor(
                                    Math.random() * (150 - 15 + 1) + 15
                                );

                                return (
                                    <li
                                        key={i}
                                        style={{
                                            left: `${generateRandomNum({ min: 0, max: 90 })}%`,
                                            width: randomDimensions,
                                            height: randomDimensions,
                                            animationDelay: `${i % 2 ? generateRandomNum({ min: 0, max: 20 }) : 0}s`,
                                            animationDuration: `${generateRandomNum({ min: 10, max: 50 })}s`,
                                        }}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                    <div className="image-overlay" />
                </div>
                <div className='col col-md-1'></div>
            </div>
            <div className='col col-md-12 d-flex flex-md-row flex-column'>
                <div className='col col-md-1 '></div>
                <div className='col col-md-10 d-flex flex-row course'>
                    <div class="container">
                        <div class="row">
                            <div class="col col-12 d-flex flex-md-row flex-column">
                                <div class="col col-md-6 p-4 ">
                                    <div class="card maincard1 mycoursecard p-3">
                                        <div class="list-group list-group-flush scrollbars">
                                            {data.map((val) => (
                                                <text href="" class="list-group-item list-group-item-action m1 d-flex align-items-center modules"
                                                    onClick={() => handleClick(val.id)}
                                                >
                                                    {val.label}
                                                </text>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='col col-md-6 p-4'>
                                    <div class="card maincard2">
                                        <div className='rightcard'>

                                            <>
                                                {selectedId === 1 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>Java Overview</li>
                                                                <li className='sublisted'>History of Java</li>
                                                                <li className='sublisted'>Features of Java</li>
                                                                <li className='sublisted'>JDK, JRE and JVM</li>
                                                                <li className='sublisted'>Basic Syntax</li>
                                                                <li className='sublisted'>Variable and Data Types</li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {selectedId === 2 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>Basics of JSP</li>
                                                                <li className='sublisted'>JSP API, the Life cycle of JSP</li>
                                                                <li className='sublisted'>JSP in Eclipse and other IDE’s</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {selectedId === 3 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>scriptlet tag</li>
                                                                <li className='sublisted'>expression tag</li>
                                                                <li className='sublisted'>Java Do While Loop and Interview Tasks</li>
                                                                <li className='sublisted'>declaration tag</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {selectedId === 4 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>out, request, response</li>
                                                                <li className='sublisted'>config, application, session</li>
                                                                <li className='sublisted'>pageContext, page, exception</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {selectedId === 5 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>Page directive</li>
                                                                <li className='sublisted'>Include directive</li>
                                                                <li className='sublisted'>Taglib directive</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {selectedId === 6 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>Jsp:forward, jsp:include</li>
                                                                <li className='sublisted'>Jsp:useBean, Bean class</li>
                                                                <li className='sublisted'>Jsp:SetProperty & Jsp:getProperty</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {selectedId === 7 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>Servlet: What and Why?</li>
                                                                <li className='sublisted'>Basics of Web, Servlet API, Servlet Interface</li>
                                                                <li className='sublisted'>GenericServlet, HttpServlet, Servlet Life Cycle</li>
                                                                <br />
                                                                <li className='sublisted'>ServletRequest methods</li>
                                                                <li className='sublisted'>Servlet Collaboration , ServletConfig, RequestDispatcher</li>
                                                                <li className='sublisted'>SendRedirect, ServletConfig methods, ServletConfig example</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            <>
                                                {selectedId === 8 && (
                                                    <div className=''>
                                                        <div className='topics'>
                                                            Topics:
                                                        </div>
                                                        <div className='listed'>
                                                            <ul className='diamondlisted'>
                                                                <li className='sublisted'>Cookies, Hidden Form Field</li>
                                                                <li className='sublisted'>URL Rewriting, HttpSession</li>
                                                                <li className='sublisted'>Project Development</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-12 col-12 d-flex flex-column flex-md-row justify-content-center mt-5 gap-3">
                                <div className='col col-md-6 col-12 d-flex justify-content-md-end justify-content-center'>
                                    <span className='do_you_like'>Do you like the Curriculum?</span>
                                </div>
                                <div className='col col-md-6 col-12 d-flex justify-content-md-start justify-content-center mb-5'>
                                    <button type="button" class="request" onClick={showModal}>Request Batch</button>
                                </div>
                                <ModalPage
                                    onFinishFailed={onFinishFailed}
                                    onFinish={onFinish}
                                    handleCancel={handleCancel}
                                    showModal={showModal}
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    sendEmail={sendEmail}
                                    form={form}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col col-md-1 col '></div>
            </div>
        </div>
    )
}

export default CourseContent